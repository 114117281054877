export const allTestimonials = [
  {
    quote: "Mr Hill is patient and understanding and encouraging.",
    author: "Amy Jurss - Student",
  },
  {
    quote:
      "Pypr really enjoys her guitar lessons with Mr Hill.  She has been having these for a couple of years now and never complains about going to lessons.  Damien Hill has a lot of patience and is very calm with the students.  It is a testament to Damien's skills when you see the students playing in concerts in front of the parents. I am sure Pypr will continue to advance in guitar under Damien's teachings.",
    author: "Kerry Bull - Parent",
  },
  {
    quote:
      "Our daughter has taken up guitar this year, after playing violin for the past 3 years.  She gave herself the challenge of both instruments, but knowing Damien, we knew she would enjoy it.  She has taken to it so well & impresses us every week with her prowess.  She loves the variety of music she learns and she also likes being able to choose popular pieces she likes.  Even though the lessons are quite short this year, Damien has shown her so much & that is very impressive to us.",
    author: "Faye Davis-Goodall - Parent",
  },
  {
    quote:
      "My son very much enjoys his weekly lessons with Damien and comes away from his lessons each week having learnt not only something new but songs that are current and he can relate to.  As the lessons are carried out at school and parents are not present, the weekly feedback from Damien is very well received.  I would recommend Damien to anyone wanting to learn to play the guitar.",
    author: "Emma Johnson - Parent",
  },
  {
    quote:
      "My son has been attending lessons with Damien for 3 years and ensemble. Damien makes his lessons relevant for the students with a mixture of music for them to learn. I especially enjoy the 'epic' modern songs, keeps his students engaged. Damien is readily available to discuss my child's progress and advice.",
    author: "Michelle Denholm-Winter - Parent",
  },
  {
    quote:
      "Mr Hill is a youthful teacher that connects with students on their level making learning fun and engaging.",
    author: "Julie Jurss - Parent",
  },
  {
    quote:
      "Jett has never played a guitar before and seems to really be enjoying your lessons.  You are making it fun for him and choosing songs that he likes to play to keep him interested.  In the term he has been playing he has picked up so much.",
    author: "Nadia Cluff - Parent",
  },
  {
    quote:
      "I highly recommend guitar tuition from Damien. My son is learning a lot of songs, techniques and skills. I am regularly updated on his progress and suggestions on how to support his practise at home. My son really enjoys playing guitar and his lessons with Damien.",
    author: "Samantha Lemmon - Parent",
  },
  {
    quote:
      "I have had two boys learn guitar with Damien Hill. They have learned guitar very quickly and have really enjoyed their guitar lessons with him. He is fun and connects to them on their level. All lessons have songs which are familiar to them and fun which makes it easier to get them to practice and makes them keen to learn. He contacts me via email straight after each lesson with an update on their progress and a new song to learn or practice. I would definitely recommend Damien as a guitar teacher.",
    author: "Julie Sullivan - Parent",
  },
  {
    quote:
      "Damien is very encouraging and supportive. My son loves his lessons and that he learns to play his favourite songs.",
    author: "Tamara Forde - Parent",
  },
  {
    quote:
      "Damien is great with the kids. Weekly feedback allows me to keep track of my daughter's lessons.",
    author: "Sara Adams - Parent",
  },
  {
    quote:
      "Damien is a wonderful, patient teacher and has really given Aimee confidence with her guitar! A great pace for her to learn at!",
    author: "Sinead Davies - Parent",
  },
  {
    quote:
      "Damien is a very approachable teacher who shows passion in his work. My son really enjoys playing the popular songs he is given as he recognises the tunes. Damien has different approaches when students feel challenged to keep things exciting. He has a positive attitude and seems to really enjoy what he does!",
    author: "Susanne Thornhill - Parent",
  },
  {
    quote:
      "Damien is a really great teacher that my son enjoys working with. Damien always works with current material that Nicholas can relate to. He has been teaching Nicholas for about 18 months now and Nicholas has improved greatly over that time. Damien also constantly gives feedback on the lessons which is really helpful.",
    author: "Carl Franklin - Parent",
  },
  {
    quote:
      "Clancy enjoys guitar, thanks to the excellent supportive learning environment provided by Mr Hill.",
    author: "Sarah Swan - Parent",
  },
  {
    quote:
      "I enjoy learning guitar because of the interesting and fun content that Mr Hill provides. He makes the boring theory fun and always encourages students to try their best.",
    author: "Clancy Swan - Student",
  },
  {
    quote:
      "I like playing guitar because I like music.  Mr Hill is cool!  It is fun and I love learning all the songs we play and enjoy learning more and more about music.",
    author: "Pypr Bull - Student",
  },
]

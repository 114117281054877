import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useInterval } from "react-use"
import { MdArrowForward, MdArrowBack } from "react-icons/md"

import { SmallHeroLayout } from "../components/layouts"
import Image from "../components/generic/image"
import SEO from "../components/seo"
import { Testimonial } from "../components/testimonial"

import { allTestimonials } from "../constants/testimonials"

const ImgContainer = styled.div`
  margin: 2.5rem 0;
  &:first-of-type {
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 920px) {
    flex: 1 0 50%;
    margin: 0;
    border: solid 1px rgba(0, 0, 0, 0.25);

    &:first-of-type {
      margin-top: 0;
    }

    > .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    flex: 1 0 50%;
    background-color: #35373a;
  }

  > div:not(${ImgContainer}):nth-of-type(even) {
    background-color: #1d3661;
  }

  @media screen and (min-width: 920px) {
    flex-direction: row;
    flex-wrap: wrap;
    border: solid 1px rgba(0, 0, 0, 0.25);

    > div {
      background-color: #1d3661;
    }

    > div:not(${ImgContainer}):last-of-type {
      background-color: #35373a;
    }
  }
`

const LastTestimonial = styled.div<{ opacity?: number }>`
  flex: 1 1 100%;
  background-color: #35373a;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: none;
  }

  > div {
    transition: opacity linear 0.5s;
    opacity: ${(p) => p.opacity};
  }

  @media screen and (min-width: 920px) {
    button {
      display: block;
      border: none;
      background: none;
      color: white;
      margin: 0 0.5rem;
      padding: 0.5rem;
      opacity: 0.35;
      cursor: pointer;
    }

    button:hover {
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`

const PageHeading = styled.h1`
  display: none;

  @media screen and (min-width: 920px) {
    display: block;
    text-align: center;
  }
`

const PageSubheading = styled.h2`
  display: none;

  @media screen and (min-width: 920px) {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
  }
`

const TestimonialsPage = () => {
  // we're statically displaying 2 testimonials so remove them from the rotation queue
  const numTestimonials = allTestimonials.length
  const [timerInterval, setTimerInterval] = useState<number | undefined>(8000)
  const [testimonialOpacity, setTestimonialOpacity] = useState(1)
  const [testimonialIndex, setTestimonialIndex] = useState(0)

  useInterval(() => {
    const nextIndex = (testimonialIndex + 1) % numTestimonials
    setTestimonialOpacity(0)

    setTimeout(() => {
      setTestimonialIndex(nextIndex)
    }, 500)
  }, timerInterval)

  useEffect(() => {
    setTestimonialOpacity(1)
  }, [testimonialIndex])

  const cycleTestimonial = (direction: any) => {
    const i = direction === "back" ? -1 : 1

    let nextIndex
    if (testimonialIndex + i >= 0) {
      nextIndex = (testimonialIndex + i) % numTestimonials
    } else {
      nextIndex = numTestimonials - 1
    }

    setTimerInterval(undefined)
    setTestimonialOpacity(0)
    setTestimonialIndex(nextIndex)

    setTimeout(() => {
      setTestimonialOpacity(1)
      setTimerInterval(8000)
    }, 500)
  }

  return (
  
    <SmallHeroLayout pageTitle="Testimonials">
      <SEO title="Testimonials" />

      <PageHeading>Testimonials</PageHeading>
    
      <PageSubheading className="subtitle">
        Hear what our students and their families think
      </PageSubheading>

      <TestimonialsContainer>
        <ImgContainer>
          <Image src="/assets/images/GuitarStudents.jpg" />
        </ImgContainer>

        <Testimonial
          author={allTestimonials[allTestimonials.length - 1].author}
        >
          {allTestimonials[allTestimonials.length - 1].quote}
        </Testimonial>
        <Testimonial
          author={allTestimonials[allTestimonials.length - 2].author}
        >
          {allTestimonials[allTestimonials.length - 2].quote}
        </Testimonial>

        <ImgContainer>
          <Image src="/assets/images/DamienAndGuitarStudent.jpg" />
        </ImgContainer>

        <LastTestimonial opacity={testimonialOpacity}>
          <button onClick={() => cycleTestimonial("back")}>
            <MdArrowBack size="2rem" />
          </button>
          <Testimonial author={allTestimonials[testimonialIndex].author}>
            {allTestimonials[testimonialIndex].quote}
          </Testimonial>
          <button onClick={() => cycleTestimonial("forward")}>
            <MdArrowForward size="2rem" />
          </button>
        </LastTestimonial>
      </TestimonialsContainer>
    </SmallHeroLayout>
  )
}

export default TestimonialsPage

import React from "react"
import styled from "@emotion/styled"

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 24rem;
  transition: all linear 0.1s;

  @media screen and (min-width: 920px) {
    flex: 1 1 50%;
  }
`

const InnerContainer = styled.div<{ opacity?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  overflow: hidden;
  transition: opacity linear 0.5s;
  opacity: ${(p) => (p.opacity !== undefined ? p.opacity : 1)};

  span {
    font-size: 1.2rem;
    font-style: italic;
    color: #95a8c9;
    margin-top: 1rem;
  }

  &::after {
    color: #b8c5db;
    font-size: 2.5rem;
    padding: 1rem;
    position: absolute;
    opacity: 0.075;
    top: 0;
    left: 0;
  }
`

const Quote = styled.q`
  color: #b8c5db;
  font-size: 1.5rem;
  quotes: none;
`

export const Testimonial = ({
  children: quote,
  author,
  opacity
}: any) => {
  return (
    <OuterContainer>
      <InnerContainer>
        <Quote>{quote}</Quote>
        <span>{author}</span>
      </InnerContainer>
    </OuterContainer>
  )
}
